<template>
  <div class="el-header">
    <div class="haed-conten">
      <div class="logo"></div>
      <div class="header-r">
        <el-menu :default-active="defaultActive" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item :index="'首页'">首页</el-menu-item>
          <el-menu-item v-for="(item, index) in  data " :key="index" :index="item.name">{{ item.name
          }}</el-menu-item>
          <el-menu-item :index="'工业元宇宙'">工业元宇宙</el-menu-item>
          <el-menu-item :index="'联系我们'">联系我们</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myfooter",
  props: ["data"],
  data() {
    return {
      defaultActive: "首页",
      url:"https://www.11xyz.com/it_kc_index"
    };
  },
  created() {
    this.defaultActive = this.$route.meta?.title ? this.$route.meta?.title : "首页"
  },
  methods: {
    handleSelect(key) {
      let routes = this.$router.options.routes;
      let curRoute = routes.find(item => item.meta?.title == key);
      let targetPath = "";

      switch (key) {
        case "首页":
          targetPath = "/";
          break;
        case "工业元宇宙":
          targetPath = "/metaverse";
          break;
        case "联系我们":
          targetPath = "/us";
          break;
        default:
          if (curRoute) {
            targetPath = curRoute.path;
          }
          break;
      }

      if (targetPath && this.$route.path !== targetPath) {
        this.$router.push({ path: targetPath, query: { id: curRoute?.id } });
      }
    }

  },
  watch: {
    $route(to, from) {
      this.defaultActive = to.meta?.title ? to.meta?.title : "首页"
    }
  }
}
</script>

<style lang="scss" scoped>
.el-header {

  width: 100%;
  height: 100px;
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;

  .haed-conten {
    width: 1150px;
    margin: 0 auto;
    min-width: 1150px;
    display: flex;
    justify-content: space-between;
    align-content: flex-end;

  }

  //background-color: #fff;

  .logo {
    width: 280px;
    padding: 10px;
    flex: 1;
    background-image: url(../assets/icon/logo.png);
    background-size: auto;
    background-position: left center;

  }

  .header-r {
    height: 100px;
    display: flex;
    align-items: flex-end;


  }

  .el-menu {
    // height: 10px;

    background: transparent !important;
    border-bottom: 0 solid #000 !important;

    .el-menu-item {
      color: #fff !important;
      font-size: 18px;
      background: transparent !important;
      padding: 0;
      margin: 0 20px;
    }


  }

  .is-active {
    border-bottom: 2px solid #fffdfe !important;
  }

  .el-menu-item:hover {
    background: transparent !important;
  }

}
</style>